import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import "ant-design-vue/dist/antd.css";

// Lottie animate
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

// Styles
import "@/assets/styles/main.scss";

const app = createApp(App);

app.use(router);
app.use(Antd);
app.use(Vue3Lottie);
app.mount('#app');
