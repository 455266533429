import { RouteRecordRaw } from "vue-router";

// Views
import { NotFoundView } from "@/views";

import ViewerLayout from "@/views/Layout/ViewerLayout/ViewerLayout.vue";
import { NotFoundLayout } from '@/views/Layout'
const routes: Array<RouteRecordRaw> = [
    {
        path: "/watch",
        name: "playerView",
        component: ViewerLayout,
        children: [
            {
                path: "/watch/:adId?/:viewerId?",
                name: "watch",
                props: true,
                component: () =>
                    import(
            /* webpackChunkName: "player" */ "../views/PlayerView/PlayerView.vue"
                    ),
            }
        ],
    },
    {
        path: "/calibration",
        name: "calibrationViewer",
        component: ViewerLayout,
        children: [
            {
                path: "",
                name: "calibration",
                props: true,
                component: () =>
                    import(
            /* webpackChunkName: "calibration" */ "../views/CalibrationView/CalibrationView.vue"
                    ),
            },
        ],
    },
    {
        path: "/:catchAll(.*)",
        component: NotFoundLayout,
        children: [
            {
                path: "",
                component: NotFoundView,
            }
        ]
    }
];

export default routes;